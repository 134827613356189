import { graphql, Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HeaderOne from "@/components/header-one";
import Layout from "@/components/layout";
import MenuContextProvider from "@/context/menu-context";
import Footer from "@/components/footer";
import Img from "@/components/img";
import Seo from "@/components/seo";
import imageUrlBuilder from '@sanity/image-url'
import clientConfig from '../../client-config'
import PortableText from '@/components/portableText'




export const query = graphql`
  query PostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      mainImage {
        asset {
          url
        }
      }
      publishedAt(formatString: "MMM Do, YYYY")
      slug {
        current
      }
      title
      _rawBody
      tags
      author {
        name
        hideFromFront
        bioExcerpt
        slug {
          current
        }
        image {
          hotspot{
              height
              width
              x
              y
          }
          crop {
              bottom
              left
              right
              top
          }
          asset {
            url
          }
        }
      }
    }
    articles: allSanityPost(limit:3) {
      nodes {
        publishedAt(formatString: "MMM Do, YYYY")
        slug {
          current
        }
        title
        mainImage {
          asset {
            url
          }
        }
        author {
          name
        }
        _id
        excerpt
      }
    } 
    siteCategories: allSanityCategory {
      nodes {
        title
        id
      }
    }
  }
`;

const FerraraPost = (props, {location}) => {
  const { data } = props;
  const post = data && data.post;
  const articles = data.articles.nodes;
  const siteCategories = data.siteCategories.nodes;
  
  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }


  return (
    <MenuContextProvider>
    {post && (
      <Layout PageTitle={post.title}>
      <Seo
      title={post.title}
      description="Ferrara Fiorenza PC offers a blend of expertise and thoughtful representation in analyzing and solving the challenges faced by school districts, BOCES and all types of employers."
      image={post.mainImage ? post.mainImage.asset.url : "../../breadcrumb.jpg" }
      location={post.slug}
      />
      <HeaderOne />
        <section className="blog_details_section section_padding">
            <Container className="mt-5">
                <Row>
                <Col lg={8}>
                    <div>
                        <div className="blog_left_box">
                            <div className="bl_share_img">
                                {(() => {
                                    if (post.mainImage != null) {
                                    return (
                                        <Img src={post.mainImage.asset.url}  className="img-fluid rounded" />
                                        )
                                    } else {
                                    return (
                                        <Img src="../../breadcrumb.jpg"  className="img-fluid rounded" />
                                        )
                                    }
                                })()}
                                
                                {post.publishedAt != null &&  <span className="blog_date">{post.publishedAt}</span> }
                            </div>
                            <div className="blog_share_details blog_share_details__details">
                            <span className="comment_author">
                            </span>
                            <h1>{post.title}</h1>
                            <PortableText blocks={post._rawBody}
                            />
                            </div>
                        </div>
                        {post.tags.length > 0 &&
                          <div className="blog_social_share_box">
                            <div className="share_box_left">
                              <p>Tags:{post.tags.map((item,index)=> (<> <span key={index}>{item} </span> </>))}</p>
                            </div>
                          </div>
                        }
                    </div>
                    {post.author != null && post.author.hideFromFront !== true &&
                    <div className="blog_author_box">
                        <div className="author_img">   
                            <img src={urlFor(post.author.image).width(200).height(200).url()} alt="attorney" className="rounded-full float-left"/>
                        </div>
                        <div className="author_bio">
                            <h2>{post.author.name}</h2>
                            <p>{post.author.bioExcerpt}</p>
                            <Link to={"/attorneys/" + post.author.slug.current} className="author_profile">View Attorney Profile</Link>
                        </div>
                    </div>
                    }
                </Col>

                {/* Side bar */}
                <Col lg={4}>
                  {/* Search box */}
                  <div className="blog_details_right">
                    {/* Latest Posts */}
                    {articles != null &&
                    <div className="blog_right_box mb-30">
                        <div className="latest_post">
                          <h2>Latest Posts</h2>
                          <ul>
                          {articles.map((article,index)=> (
                            <li key={index}>
                              <a href={article.slug.current}>
                                <div className="img-block">
                                  {article.mainImage != null &&  <Img src={article.mainImage.asset.url} alt={article.title} className="img-fluid" /> }
                                  {article.mainImage == null && <Img src="../../breadcrumb.jpg" alt={article.title} className="img-fluid" />}
                                </div>
                                {article.title}
                              </a>
                            </li>
                          ))}
                          </ul>
                        </div>
                    </div>
                    }

                    {/* Categories */}
                    <div className="blog_right_box mb-30">
                      <div className="widget_categories">
                        <h2>Categories</h2>
                        <ul>
                        {siteCategories.map(category=> (
                          <li key={category.id}>
                            <span>
                              {category.title} <i className="fa fa-angle-right"></i>
                            </span>
                          </li>
                        ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
                </Row>
            </Container>        
        </section>
        <Footer />
      </Layout>
      )}
    </MenuContextProvider>
  );
};

export default FerraraPost;
